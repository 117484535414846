import * as React from "react"
import { graphql } from "gatsby"
import Gallery from '@browniebroke/gatsby-image-gallery'


import Layout from "../components/layout"
import Seo from "../components/seo"


const PicturesPage = ({ data }) => {
    const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
    // `images` is an array of objects with `thumb` and `full`
    return (
        <Layout>
            <Seo title="Pictures" />
            <div className="fluid-container">
                <div className="row">
                    <article id="portal-column-content" className="cell col-sm-12 mbottom-default">
                        <div className="column">
                            <h1>Pictures</h1>
                        </div>
                        <Gallery images={images} />
                    </article>
                </div>
            </div>
        </Layout>

    )
}

export const pageQuery = graphql`
  query Pictures2023 {
    allFile(filter: {relativePath: {regex: "/^pictures/"}}) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 330
              height: 270
              placeholder: BLURRED
              quality: 70
            )
            full: gatsbyImageData(
              layout: FULL_WIDTH
              quality: 70
            )
          }
        }
      }
    }
  }
`

export default PicturesPage
